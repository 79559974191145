export const VENDORS_BEVAKA_STATS = ['vitec', 'mspecs', 'maklarhuset', 'fasad', 'skm'];
export const RULES_APPLIED_STATS = ['rule1', 'rule2', 'rule3', 'rule4'];
// export const VENDORS = ['vitec', 'mspecs', 'maklarhuset', 'fasad','skm'];
export const VENDORS = ['vitec', 'mspecs', 'maklarhuset', 'fasad'];
export const VENDORS_PROJECT = ['vitec'];
export const ERRORS = ['importer', 'manipulator', 'listener'];

// encrypt Keys
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const SECRET_IV = process.env.REACT_APP_SECRET_IV;
export const ENCRYPT_METHOD = process.env.REACT_APP_ENCRYPT_METHOD;

export const WORDPRESS = process.env.REACT_APP_WORDPRESS_ENDPOINT;

// importer URLs
export const IMPORTER_URL = process.env.REACT_APP_API_IMPORTER_URL;
//REACT_APP_ADS_API_ENDPOINT
export const STATUS = [
  { label: 'Active', value: true },
  { label: 'De-Active', value: false },
];

export const USER_GROUP_ID = [
  { label: 'Super Admin', value: 'super_admin' },
  { label: 'Brand Admin', value: 'brand_admin' },
  { label: 'Constructor', value: 'constructor' },
  { label: 'System User', value: 'system_user' },
  { label: 'Frontend User', value: 'frontend_user' },
  { label: 'Property Stats Api User', value: 'property_stats_api_user' },
  { label: 'Bevaka Slutpris', value: 'bevaka_slutpris' },
  { label: 'X Closest Objects', value: 'boneo_closest_objects' },
  { label: 'Quicksight User ', value: 'quicksight_user' },
  { label: 'Realtor User', value: 'realtor_user' },
  { label: 'Maklare Portal Objectlist User', value: 'maklare_object_list' },
  { label: 'Maklare Portal Buy Lead', value: 'maklare_buy_lead' },
  { label: 'Lead Verification Role', value: 'lead_verification' },
  { label: 'Office Lead Group', value: 'office_lead_group' },
  { label: 'Office grant permissions', value: 'office_grant_permissions' },
  { label: 'Agent Lead', value: 'agent_lead' },
  { label: 'Kontorets annonser', value: 'office_objects' },
  { label: 'Kontorets statistik', value: 'office_summary_statistics' },
  { label: 'BevakaMatch', value: 'bevaka_match' },
];

export const BRAND_LIST = [
  { label: 'Mäklarfirman Widerlöv & Co AB', value: '5e689c5a35d06f3cc341092d' },
  { label: 'Svenska Mäklarhuset Älvsjö/Bandhagen', value: '5e6604f6ce993ec80af669d2' },
  { label: 'Länsförsäkringar Fastighetsförmedling Haninge', value: '5e689c5a35d06f3cc341092c' },
];

export const AGENCY_LIST = [
  { label: 'Mäklarfirman Widerlöv & Co AB', value: '5e9d6b6c07d0380007ec6e57' },

  { label: 'Svenska Mäklarhuset Upplands Väsby', value: '5e9f3e87e3944a00082ee958' },
  { label: 'Svenska Mäklarhuset Vällingby', value: '5e9ed9dd64e89a00071b4b7e' },

  { label: 'Länsförsäkringar Fastighetsförmedling Oskarshamn', value: '5ea036e0ffc5860008be2ab2' },
  { label: 'Länsförsäkringar Fastighetsförmedling Åstorp', value: '5e9fdabcc47a660008179a27' },
];

export const AGENT_LIST = [
  { label: 'Ulf Sjöholm', value: 'CANVANDARE3GN3I97DO1HOQ8C1' },
  { label: 'Jonas Bedinger', value: 'CANVANDARE3M8RJT62S8AHEQBO' },
  { label: 'Olof Edelsvärd', value: 'CANVANDARE3TCJ8S4UA9PC8N2F' },
];

export const genders = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

export const SCREENS = [
  'dashboard',
  'propertyresponse',
  'errors',
  'notifications',
  'propertyrevision',
  'property',
  'packages',
  'bevaka',
  'realtor-bevaka',
  'leads-invoice',
  'seller-leads',
  'bevakamaillogs',
  'agent',
  'places',
  'users',
  'resetpassword',
  'agency',
  'roles',
  'brands',
  'projects',
  'constructor',
  'inquiry',
  'places-utland',
  'bevaka-stats',
  'bevaka-stats-by-brand',
  'bevaka-stats-by-agency',
  'bevaka-stats-by-agent',
  'bevaka-stats-by-location',
  'bevaka-stats-active-users',
  'bevaka-stats-active-bevaka',
  'bevaka-stats-bevaka-interaction',
  'indexing',
  'advertisement',
  'logs',
  'project-response',
  'project-errors',
  'project-notifications',
  'landing-pages',
  'landing-pages-logs',
  'vendor-settings',
];

export const ROLE_BASE_SCREEN_ACCESS = {
  boneo_super_admin: { value: 'super_admin', screen: SCREENS },
  boneo_brand_admin: {
    value: 'brand_admin',
    screen: ['dashboard', 'bevaka', 'bevakamaillogs', 'seller-leads', 'resetpassword'],
  },
  // Use this for dev instance
  // a_boneo_property_stats_api_user: {
  //   value: 'property_stats_api_user',
  //   screen: [],
  // },
  // Use this for staging and live instance
  boneo_property_stats_api_user: {
    value: 'property_stats_api_user',
    screen: [],
  },
  boneo_constructor: {
    value: 'constructor',
    screen: ['projects', 'inquiry', 'resetpassword'],
  },
  frontend_user: {
    value: 'frontend_user',
    screen: [],
  },
  bevaka_slutpris: {
    value: 'bevaka_slutpris',
    screen: [],
  },
  boneo_closest_objects: {
    value: 'boneo_closest_objects',
    screen: [],
  },
  boneo_bevaka_match: {
    value: 'boneo_bevaka_match',
    screen: [],
  },
  boneo_verify_lead: { value: 'boneo_verify_lead', screen: ['seller-leads'] },
};

export const WEBSITE_URL =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000'
    : `${process.env.REACT_APP_WEBSITE_URL}`;
export const S3_LANDING_PAGE_SAMPLE_CSV = `${process.env.REACT_APP_LANDING_PAGE_SAMPLE_CSV}`;

export const WHY_INTERETED_IN_SLUT_PRICE = {
  1: 'Ska köpa bostad i området',
  2: 'Ska sälja bostad i området',
  3: 'Jag är nyfiken',
};

export const QUICKSIGHT_USER_TYPE = [
  { label: 'Admin', value: 'admin' },
  { label: 'Brand', value: 'brand' },
  { label: 'Agency', value: 'agency' },
  { label: 'Agent', value: 'agent' },
];

export const OFFICE_STATISTICS_USER_TYPE = [
  { label: 'Admin', value: 'admin' },
  { label: 'Agency', value: 'agency' },
];
